
import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/App.scss';
import 'nprogress/nprogress.css';

import Router from 'next/router';
import getConfig from 'next/config'
import NProgress from 'nprogress';

import * as Sentry from '@sentry/node'
import { RewriteFrames } from '@sentry/integrations'

import AppProvider from 'AppRovider';
import { FavoriteProvider } from 'components/Favorite';
import { API_HOST } from 'Config';


Router.events.on('routeChangeStart', (url) => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, 'app:///_next');
          return frame
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
  })
}

function App({ Component, pageProps, err }) {
  return (
    <AppProvider
      classifiers={pageProps.classifiers}
    >
      <FavoriteProvider>
        <Component {...pageProps} err={err} />
      </FavoriteProvider>
    </AppProvider>
  )
}

App.getInitialProps = async ({ Component, ctx }) => {
  let pageProps = {};

  if (ctx.req) {
    const classifiersResponse = await fetch(`${API_HOST}api/client/classifier/`);
    pageProps['classifiers'] = await classifiersResponse.json();
  }

  if (Component.getInitialProps) {
    pageProps = {... await Component.getInitialProps({ ...ctx }) };
  }

  return { pageProps: { ...pageProps } };
};

export default App;
